<template>
    <v-footer
        app
        clipped
        :color="$config('settings.layoutColors.footerBackground')"
        min-height="40"
        dark
    >
        <h4
            class="font-weight-regular"
        >© {{ new Date().getFullYear() }} — {{ $config('settings.companyName')}}</h4>
    </v-footer>
</template>
<script>
export default {
    name: "app-footer",
};
</script>

<style scoped>
</style>